// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//require("@rails/ujs").start()
//require("turbolinks").start()
//require("@rails/activestorage").start()
//require("channels")
require("@fortawesome/fontawesome-free")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

document.addEventListener('DOMContentLoaded', () => {

    // Bulma notification box delete button
    (document.querySelectorAll('.notification .delete') || []).forEach((button) => {
        let target = button.parentNode;
        let nextUp = target.parentNode;
        while (nextUp != null && nextUp.classList) {
            if (nextUp.classList.contains('delete-target')) {
                target = nextUp;
                break;
            }
            nextUp = nextUp.parentNode;
        }
        button.addEventListener('click', () => {
            target.parentNode.removeChild(target);
        });
    });

    // Bulma navbar burger(s)
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
    if ($navbarBurgers.length > 0) {
        $navbarBurgers.forEach(el => {
            el.addEventListener('click', () => {
                const target = el.dataset.target;
                const targeteMenu = document.getElementById(target);
                if (targeteMenu) {
                    el.classList.toggle('is-active');
                    targeteMenu.classList.toggle('is-active');
                }
            });
        });
    }
});

